.footer {
  background-color: #f5f5f5;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  color: gray;
  &.footer a {
    color: gray;
    &:hover {
      text-decoration: none;
    }
  }
}

.footer-text-logo {
  display: flex;
  font-size: 1.1rem;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

.footer{
  a {
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
}