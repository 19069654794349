.ui-table__cell:last-child .ui-box {
    margin-left: auto;
}

.action-list {
    position: absolute;
    right: 0;
    z-index: 100;
    background-color: var(--colorNeutralBackground2);
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1.6rem 1.8rem -0.4rem;
    border: 0.5px solid #d1d1d1;

    & .action-list-item {
        margin: 0;
        padding: 0.8rem 1rem;
        cursor: pointer;
        display: flex;
        gap: 1rem;
        align-items: end;

        &:hover {
            background-color: var(--colorNeutralBackground2Hover);
        }
    }
}

.input-list-body .wide-cell {
    min-width: fit-content;
    & :is(.ui-table__cell, .ui-table__cell__content) {
        width: 100%;
    }

    & input[type='text'], 
    & textarea {
        width: 100%;
        padding: 0.5rem 1.2rem;
    }
}

.input-list-header {
    border-bottom: none;
}

.input-list-body p.input-list__input__name {
    padding: 0.5rem 1.2rem;
    line-height: 1.239;
}

.tagesordnungInputList {
    & .ui-table__row {
        min-height: 4.8rem;
        height: fit-content;
    }

    & textarea {
        resize: none;
    }

    & .radio-group-wrapper {
        display: none;
    }

    & .input-list__input__dauer {
        width: 50px;
        padding: 0.5rem;
    }

    & .first-cell .ui-box {
        display: flex;
        & .input-list__input__reihenfolge,
        & .input-list__input__sequenz {
            width: 35px;
        }
        & button {
            min-width: 2rem;
        }

        & button[disabled] {
            color: rgb(200, 198, 196);
        }
    }

    & .editFinalizationBtnGroup {
        display: none;
    }

    & .table-row__in-edit .editFinalizationBtnGroup {
        display: inline-flex;
 
    }

    & .table-row__in-edit .loadActionList {
        display: none;
    }
} 

.sitzung-form__content .input-list-toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 100;
    background-color: white;
}

.sitzung-form__content .ui-divider {
    position: -webkit-sticky;
    position: sticky;
    top: 32px;
    align-self: flex-start;
    z-index: 100;
    background-color: white;
}

.sitzung-form__content .tagesordnungInputList {     
    & .ui-table__row:first-of-type {
        position: -webkit-sticky;
        position: sticky;
        top: 41px;
        align-self: flex-start;
        z-index: 100;
    }

    & .ui-table__cell.wide-cell {
        width: 350px;

        & .ui-table__cell__content{
            width: 350px; 
        }

        & input{
            width: 350px
        }

        & .ui-table__cell.wide-cell {
            width: 350px;
        }
    }
}

.protocol-creation__wrapper {
    & .ui-divider {
        display: none;
    }

    & .tagesordnungInputList .ui-table__row:first-of-type {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        align-self: flex-start;
        z-index: 100;
    }

    & .tagesordnungInputList {
        // & .ui-table__row.input-list-header {
        //     display: none;
        // }


        & .ui-table__row > .ui-table__cell:first-child > .ui-table__cell__content > button.ui-button {
            display: none;
        }
    }
}
.icon-add-color{
    color: #3171c4;
}
.icon-check-color{
    color: #237848;
}
.icon-close-color{
    color: #c4314b;
}

.input-list-wrapper{
    min-width: 100% !important;
    max-width: 100% !important;
}

.input-list-wrapper.disabled {
    opacity: 0.75;
    
    & .input-list-toolbar {
        display: none;
    }

    & .ui-table__cell:last-child {
        display: none;
    }
}

.table-container{
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-x: scroll;
}

.table-container-overflow{
    overflow-x: scroll;
}

.attachment-row {
    display: flex;
}

.custom-table-cell-layout .fui-TableCellLayout__main {
    display: flex;
    justify-content: center;
    align-items: center;
  }