.form__registration {
    display: flex;
    flex-direction: column !important;
    max-width: 1000px;
    padding: 30px 40px;
    gap: 8px;

    .info-container {
        display: grid;
        grid-template-columns: 30px 1fr;
        row-gap: 5px;
        text-wrap: auto;
      }
}

.notification-toast {
    position: absolute;
    width: 100%;
    padding-top: 10px;
}

.field-style{
    display: flex !important;
    flex-direction: column;
    width: 100% ;
    background-color: transparent;
}

.field-flex{
    width: 100%;
    display: flex !important;
    gap: 5px;
    @media only screen and (max-width: 920px) {
        flex-direction: column;
    }
}

.register-btn{
    width: 100%;
    display: flex;
    justify-content: end;
}