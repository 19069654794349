.page-content.margins-sitzung.page-container {
  height: fit-content;
  margin: 0;
  width: 100%;
}

.body {
  width: 100%;
  height: calc(100vh - 15px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-center {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-content {
  max-width: 800px !important;
  margin: auto;
  height: 100%;
  padding-bottom: 20px;
}

.title-container h1 {
  line-height: 3rem;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.sitzung-started {
  font-weight: bold;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: auto;
  padding-top: 20px;
}

.centered-content {
  height: 100%;
  width: 90%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
  font-weight: bold;
  font-size: 2rem;
}

.title-container {
  margin: auto;
  width: 90%;
}

.logo-style {
  object-fit: cover;
  height: 40px;
  width: 70px;
}

.text-style {
  margin: 0;
  font-weight: bold;
}

.text-left {
  max-width: 90%;
  display: flex;
  justify-content: start;
  margin-bottom: 0;
  padding-bottom: 0;
}

.form-style {
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 10px;
}

.konfig-box {
  display:flex;
  justify-content: center;
}

.feedback-sent {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.kommentar-section {
  width: "100%";
  height: "100px";
}

.mt-7 {
  margin-top: 7px;
}

.sitzungs-name {
  font-weight: bold;
  margin-left: 6px;
}

.konfig-box-message {
  width: 90%;
  text-align: left;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.fs-40 {
  font-size: 40px;
}

.ausloggen-position {
  position: absolute;
  right: 0;
}

.w-100 {
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .form-style {
    min-width: 90%;
  }

  .centered-content {
    font-size: 1.5rem;
  }

  .text-left {
    width: 90%;
    display: flex;
    justify-content: start;
  }
}
