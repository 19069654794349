.item-style {
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  padding: 8px 15px !important;
  &:hover {
    background-color: #f5f5f5f5 !important;
  }
}

.field-style {
  width: 100%;
  margin-top: 8px;
}

.field-style-details{
  grid-column: span 1 !important;
  display: flex !important;
  flex-direction: column !important;
};
.input-style {
  width: 100%;
}

.combobox-style{
  width: 100%;
}

.sitzung-form__wrapper {
    width: 100%;
}

.sitzung-form__header {
  grid-area: header;
  max-height: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 25px;

  & .header__sitzung-name {
    font-size: 2rem;
    font-weight: 800;
  }

  & .ui-alert {
    padding: 20px 30px;
    gap: 15px;
  }
}

.meeting-form-dialog-container {
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 920px) {
        flex-direction: column;
        min-height: 83%;
    }
}
.sitzung-form__navigation {
  grid-area: sidebar;

  @media only screen and (max-width: 920px) {
    display: flex;
    align-items: center;
    justify-content: center;
}

    & .sitzung-form__nav-menu {
        padding: 0;
        margin: 0;
        max-width: 250px;

        @media only screen and (max-width: 698px) {
          width: 100%!important;
      }
        
        & a.ui-menu__item {
            display: flex;
            align-items: center;
            & i {
                margin-left: -14px;
                font-weight: bold;
                margin-right: 0.5rem;
                &.status__draft {
                    color: transparent;
                }
                &.status__ready {
                    color: #616161;
                }
                &.status__processed {
                    color: #237b4b;
                }
                &.status__needs-reprocessing {
                    color: #616161;
                }
            }
        }

        & a.ui-menu__item[tabindex='0']{
            background-color: #f5f5f5f5;
        }
    
        & a.ui-menu__item[tabindex='0'][aria-disabled='true']{
            background-color: transparent;
        }
    
        & .activeMenuItem {
            background-color: rgb(243, 242, 241);
        }

    & a:hover {
      text-decoration: none;
    }
  }
}

.sitzung-form__content {

  @media only screen and (max-width: 920px) {
}

  & .ui-form__field {
    & .ui-dropdown__container {
      width: 100%;
      overflow-y: hidden !important;
    }

    & .ui-datepicker {
      display: grid;
      grid-template-columns: 1fr auto;

      & .ui-input__input {
        width: 100%;
        background-color: rgb(243, 242, 241);
      }
    }

    & .disabledSelectedItem {
      opacity: 0.7;
  
      &:hover {
          background-color: rgb(255, 255, 255);
          cursor: default;
      }

      & .ui-dropdown__selecteditem__icon {
          display: none;
      }
  }
  }

  & .sitzung-form__section-title {
    font-size: 16px;
    font-weight: 600;
  }

  & .sitzung-form__section-subtitle {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0.5rem 0 -1rem 0;
  }

  & .ui-form {
    height: auto;
    min-height: 100%;
    justify-content: flex-start;

    & > * {
      margin-top: 0.5rem !important;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      & > * {
        grid-column: span 2;
      }

      & > .sitzung-form__narrow-field {
        grid-column: span 1;
      }
    }

    & > .ui-loader {
      margin: auto;
      & > * {
        margin: auto;
      }
    }
  }

  & .attendance-list__wrapper {
    & > .sitzung-form__narrow-field {
      align-self: flex-end;

      & .ui-dropdown__container {
        width: 100%;
      }
    }
  }

  & .meeting-links__wrapper {
    & p:first-child {
      margin-top: 0;
    }
  }
}
.attendance-list__wrapper{
  min-width: 100% !important;
  max-width: 100% !important;
}

.sitzung-form__footer {
  width: 95%;
  position: fixed;
  bottom: 0;
  grid-area: footer;
  align-self: end;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-bottom: 15px;

  @media only screen and (max-width: 920px) {
    height: 100%;
    width: 100%;
    position: relative;
    padding-bottom: 0;
    padding-top: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.sitzung-form__footer-last {
  height: 80px;
  width: 95%;
  position: fixed;
  bottom: 0;
  grid-area: footer;
  height: fit-content;
  align-self: end;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-bottom: 15px;

  @media only screen and (max-width: 920px) {
    width: 100%;
    position: relative;
    padding-bottom: 0;
    padding-top: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.date-picker{
   width: 50%;
}

.sitzung-details__wrapper{
  display: grid;
  padding-top: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
  row-gap: 10px;
  column-gap: 8px;

  @media only screen and (max-width: 920px) {
    display: flex;
    flex-direction: column;
}
}

.menu-item-active{
  background-color: var(--colorBrandBackground) !important;
  color: var(--colorStrokeFocus1) !important;
  &:hover{
    background-color: var(--colorBrandBackgroundHover) !important;
    color: var(--colorStrokeFocus1) !important;
    }
}


.inline-style{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-bottom: 5px !important;
}

.checkmark-show{
  color: rgb(162, 162, 162);
  
}

.checkmark-hide{
  color: transparent !important
}

.sitzung-form__label{
  margin-top: 20px !important
}

.disabled-picker{
  width: 50%;
  background-color: var(--colorNeutralBackgroundDisabled) !important;
  border: 0.5px solid var(--colorNeutralStroke1) !important;
  color: var(--colorNeutralStroke1) !important;
}

.datetime-picker{
  background-color: var(--colorNeutralBackground) !important;
  border: 0.5px solid var(--colorNeutralStrokeAccessible) !important;
  color: var(--colorNeutralForeground1) !important;
}

.combobox-style-width{
  min-width: 100% !important;
  max-width: 100% !important;
  input{
    @media only screen and (max-width: 920px) {
      width: 100%;
      padding-left: 5px;
    }
  }
}

.invitation-filter {
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 920px) {
    align-items: stretch;
    .fui-Dropdown__button {
      width: 100%;
    }
  }
}

