.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page-number-button {
  .fui-Button {
    min-width: 20px;
    font-weight: 500;

    &[aria-checked="true"] {
      font-weight: 800;
    }
  }
}
