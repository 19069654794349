.persona-style{
    display: flex;
    justify-items: center;
    align-items: center;
}

.persona{
    width:180px;
    min-width: 0px !important;
}

.primary-text{
    width: 125px;
    font-size: 1.4rem;
    overflow: hidden;
}

.secondary-text{
    font-size: 1.1rem;
}

.fields-style{
    display: flex;
    gap: 5px;
    margin-bottom: 5px !important;
    @media only screen and (max-width: 920px) {
        flex-direction: column;
    }
}

.box-style{
    margin-top: 8px;
    width: 100%;
}

.main-style{
    display: flex;
    min-width: 100% !important;
    max-width: 100% !important;
}

.anw-combobox{
    min-width: 100% !important;
    max-width: 100% !important;
    @media only screen and (max-width: 920px) {
        white-space: unset!important;
    }
}

@media only screen and (max-width: 950px) {
    .main-style{
        flex-direction: column;
    }

    .anw-input-notiz{
        margin-bottom: 10px;
    }
}