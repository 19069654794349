.check-icon-color{
  color: #237848;
}
.close-icon-color{
  color: #c4314b;
}

.fui-Dropdown {
  @media only screen and (max-width: 768px) {
    min-width: unset!important;
    max-width: 100%!important;
}
}
.fui-Combobox {
  @media only screen and (max-width: 768px) {
    min-width: unset!important;
}
}

.disabledInput{
  @media only screen and (max-width: 920px) {
    white-space: unset!important;
}
}
.ui-dropdown {
  & .ui-dropdown__items-list {
    width: calc(100% - 2px);
    left: 1px !important;
  }

  & .ui-button__content {
    font-weight: normal;
  }

  & .ui-dropdown__selected-items {
    min-height: 32px;
    padding: 0;
    max-height: fit-content;

    & > div[role="listbox"] {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0px;
      padding-left: 1.2rem;
    }

    & .ui-dropdown__selecteditem {
      margin: 0;
      max-width: fit-content;
      margin: 2.5px;
    }
  }

  &.disabledInput .ui-dropdown__selected-items {
    background-color: #f5f5f5f5;

    & .ui-dropdown__selecteditem {
      opacity: 0.7;

      &:hover {
        background-color: #f5f5f5f5;
        cursor: default;
      }

      & .ui-dropdown__selecteditem__icon {
        display: none;
      }
    }
  }
}

.container-div > .ui-form > .Konfiguration-formSection {
  margin-bottom: 0;

  &:last-of-type {
    margin-bottom: 4rem;
  }
}

.Konfiguration-formSectionTitle { 
  font-size: 16px;
  font-weight: 600;
}

.Konfiguration-dropdown .ui-button__content {
  font-weight: 400;
}

.Konfiguration-notizInput input {
  width: 100%;
}

.ui-checkbox.checkbox__isAdmin {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 0 0 10px;

  &.hidden {
    visibility: hidden;
  }
}

.Konfiguration-checklist-order{
  max-width: 30px;
  font-weight: bold;
}
.Konfiguration-checklist-name{
  font-weight: bold;
}

.Konfiguration-checklist-row{
  display: flex;
}

.Konfiguration-checklist-index{
  width: 30px;
}

.konfiguration-mitglieder-input{
  flex-direction: column;
}

.fui-TableCellLayout__content{
  width: 100%!important;
  @media (max-width: 920px) {
    width: 100%!important;
    min-width: none!important;
    max-width: none!important;
    text-wrap: wrap!important;
  }
}

.tablist-konfiguration{
  @media (max-width: 920px) {
    display: flex!important;
    flex-wrap: wrap-reverse!important;
  }
}

.checklist-buttons-order{
    flex-wrap: wrap; 
    flex-direction: column
}

.checklist-number-order{
  display: flex; 
  align-items: center; 
}
.Konfiguration-content{
  width: 100%;
  padding-top: 15px;
  @media (max-width: 920px) {
    width: 100%;
  }
}

.Konfiguration-content-wrapper {
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 0;
  }
}

.Konfiguration-navigation{
  display: flex;
  justify-content: center;
}

.konfiguration-menu{
  width: 83%;
}

.Konfiguration-container {
  max-width: 100%;
  padding-right: 4px;
  padding-left: 40px;
  padding-top: 40px !important;

  @media (max-width: 920px) {
    padding-top: 10px!important;
    padding-left: 4px;
  }

  ul.Konfiguration-navigation {
    background-color: transparent;
    width: 215px;
    z-index: 1;

    & li {
      width: 100%;
    }

    & li a:hover,
    & li a.activeMenuItem {
      text-decoration: none;
      background-color: rgb(243, 242, 241);
      //font-weight: bold;
      // border-width: 0.4rem;
      // border-radius: 0.9rem;
    }

    // & li .ui-menu__item[tabindex="0"] {
    //     background-color: rgb(243, 242, 241);
    // }

    @media (max-width: 920px) {
      flex-direction: row;
      width: 100%;
      margin-top: 10px;

      & li.ui-menu__itemwrapper > a {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }

  & .Konfiguration-form {
    justify-content: start;
  }
}

.konfiguration-dropdown{
  @media only screen and (max-width: 768px) {
    min-width: 100%!important;
  }
}

.konfiguration__save-btn-wrapper {
  display: flex;
  justify-content: end;
  gap: 1rem;
  margin-top: 0.8rem;

  & .fui-Button:first-of-type {
    margin-right: auto;
  }
}

.dialog-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
}

.Konfiguration-container .ui-menu.Konfiguration-meetingTypeTabs {
  margin-bottom: 0;
}

.input-style{
  margin: 0;
  width: 100%;
  background-color: #f5f5f5;
  border: none !important
}

.container-div{
  height: fit-content !important;
  padding-bottom: 20px !important;
  width: unset!important;
}

.menu-style{
  background-color: var(--colorBrandBackground) !important;
  color: var(--colorStrokeFocus1) !important;
  &:hover{
    background-color: var(--colorBrandBackgroundHover) !important;
    color: var(--colorStrokeFocus1) !important;
    }
}