.stepper-container{
    display: flex;
    gap: 1.5rem;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.step{
    display: flex;
    gap: 10px;
    align-items: center;
}

.step-number{
    display: flex;
    font-size: 1.3rem;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    min-width: 25px;
    max-width: 25px;
    border-radius: 15px;
    background-color: var(--colorNeutralForeground4);
    color: white;
}

.step-number-active{
    background-color: var(--colorBrandBackground);

}

.step-line{
    width: 35px;
    height: 1px;
    background-color: rgba($color: #616060, $alpha: 0.7);
}

.step-text-active{
    font-weight: bold;
}