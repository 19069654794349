.radio-group-wrapper {
    display: flex;
    gap: 1.2rem;

    & label {
        display: flex;
        gap: 0.6rem;
        align-items: center;
        cursor: pointer;
    }

    & input {
        cursor: pointer;
    }
}