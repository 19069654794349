.gremium-type-dropdown {
    width: fit-content;
    border: none !important;

    &:after {
        border-bottom: none !important;
    }

    button {
        font-size: 28px !important;

        @media only screen and (max-width: 768px) {
            font-size: 20px !important;
        }
    }
}

.auschuss-dropdown {
    width: fit-content;
    border: none !important;

    &:after {
        border-bottom: none !important;
    }

    button {
        font-size: 18px !important;
    }
}