//Grid
.ui-grid {
  gap: 20px;
  max-width: fit-content;
  justify-content: unset !important;

  & .ui-card {
    max-width: 400px;
    min-width: 312px;
    border-radius: 4px;
    cursor: pointer;

    &[aria-disabled="true"] {
      opacity: 0.6;
      cursor: default;
    }

    & .card-icon {
      font-size: 50px;
      text-align: center;
      background-color: #e8ebfa;
      color: black;
      margin: -16px -16px 0 -16px;
      padding: 20px 0 20px 0;
    }
  }
}

.cf::before{
  width: 0 !important;
}

//Delete Button

button.fui-Button.rlr4yyk.deleteButton {
  background-color: #bc2f49;
  color: white;
  font-weight: normal;
  &:hover {
    background-color: #a3283e;
  }
}

.erste-nutzung-text{
  padding-left: 40px;
  @media only screen and (max-width: 920px) {
    width: 90%;
    padding-left: 0;
  }
}

.erste-nutzung-video{
  @media only screen and (max-width: 920px) {
    width: 100%;
  }
}

.main-page-title{
  padding-left: 40px;
  @media only screen and (max-width: 920px) {
    width: 80%;
    line-height: 25px;
    text-align: center;
    padding-left: 0;
  }
}

.grid-style{
  margin-top: 30px;
  display: flex;
  gap: 20px;
  padding-left: 40px;

  @media only screen and (max-width: 920px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
}

.card-style{
  width: 25%;
  min-width: 250px;
  @media only screen and (max-width: 920px) {
    width: 80%;
  }
}

.card-style-disabled{
  width: 25%;
  min-width: 250px;
  pointer-events: none;
  background-color: var(--colorNeutralBackgroundDisabled) !important;
  @media only screen and (max-width: 920px) {
    width: 80%;
  }
}

.menu-inline{
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.card-icon-big {
  font-size: 50px;
  // text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8ebfa;
  color: black;
  max-height: 25px;
  min-height: 25px;
  margin: -16px -16px 0 -16px;
  padding: 20px 0 20px 0;
}