.ui-table.sitzung-table {
  & .dropdownMenuTrigger {
    cursor: pointer;
  }

  & .ui-table__row {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
  }

  & .ui-table__row:first-of-type .ui-table__cell__content {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;

    & i {
      padding-top: 2px;
    }
  }

  & .ui-table__row:not(:first-child) {
    cursor: pointer;
  }

  & .ui-table__cell {
    & .menu__sort-filter {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 50%;
      min-width: 200px;

      & > .ui-menu__itemwrapper {
        &:not(:first-of-type) {
          &:hover {
            background-color: white;
          }

          & .ui-menu__item:hover {
            text-decoration: none;
            cursor: auto;
          }

          & .ui-menu__itemcontent {
            display: flex;
            flex-direction: column;
          }
        }
      }

      &.menu__sort-filter_no-filter {
        & > .ui-menu__itemwrapper {
          &:hover {
            background-color: white;
          }

          & .ui-menu__item:hover {
            text-decoration: none;
            cursor: auto;
          }

          & .ui-menu__itemcontent {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    &:last-of-type .menu__sort-filter {
      right: 0;
      left: unset;
    }

    & .ui-checkbox {
      display: grid;
    }
  }
}

.table-styling{
  @media only screen and (max-width: 768px) {
    display: block!important;
    overflow-x: auto;
    white-space: nowrap;
  }

}

.controll-toolbar {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    button{
      margin-left: 0!important;
    }
  }

  & .ui-button {
    display: flex;
    align-items: end;
  }

  & .filter-reset-button {
    margin-left: auto;
  }

  & .card-icon {
    font-weight: bold;
    font-size: 16px;
  }
}

.filter-dropdown {
  min-width: 95% !important;
}

.filter-input-style {
  border: 1px solid var(--colorNeutralStroke1) !important;
  width: 95%;
  border-bottom-color: var(--colorNeutralStrokeAccessible) !important;
}

.filter-date-picker {
  width: 95%;
}

.clear-btn {
  font-size: 18px;
  position: relative;
  right: 45px;
}
