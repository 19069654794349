.quill-disabled{
    opacity: 0.6;
    pointer-events: none;
}

.quill{
    background-color: var(--colorNeutralBackground1) !important;
    color: var(--colorNeutralForeground1) !important;
    font-family: var(--fontFamilyBase) !important;
}

.ql-toolbar{
    background-color: var(--colorNeutralBackground6) !important;
    font-family: var(--fontFamilyBase) !important;
}
.dark .ql-toolbar{
    background-color: white !important;
}

.ql-editor{
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", "Arial", sans-serif !important; //var(--fontFamilyBase) ;
    -webkit-text-size-adjust: 100% !important; /* Prevent font size adjustments in mobile Safari */

     p {
        font-size: 14px !important;
        line-height: 1.2 !important;
        margin: 0 0 10px !important;
        color: #333333 !important;
    }

    h1 {
        font-size: 24px !important;
        font-weight: bold !important;
        line-height: 1.2 !important;
        margin: 20px 0 10px !important;
        color: #333333 !important;
    }

    h2 {
        font-size: 18px !important;
        font-weight: bold !important;
        line-height: 1.3 !important;
        margin: 18px 0 8px !important;
        color: #333333 !important;
    }

    a {
        color: #5b5fc7 !important;
        text-decoration: underline !important;
    }

    a:hover {
        text-decoration: underline !important;
    }

    ul, ol {
        margin: 0 0 15px 20px !important;
        padding: 0 !important;
    }

    li {
        font-size: 14px !important;
        line-height: 1.2 !important;
        color: #333333 !important;
        margin-bottom: 5px !important;
    }
}